// External packages
import { graphql } from "gatsby"
import React from "react"
import styled from "@emotion/styled"

// Components
import Layout from "../components/layouts"
import ContentModules from "../components/Page/ContentModules"
import SEO from "../components/seo"
import { ShopTheLook } from "../components/v2/ShopTheLook"
import { getFaqSchema } from "../utils/seo/getFaqSchema"
import { Page } from "../components/v2/contentful/Page"
import {
  BreadcrumbsListItem,
  ContentfulGrid,
  ContentfulPage,
  ContentfulShopTheLook,
} from "types"
import { useStore } from "../context/NewStoreContext"
import { countryLookupName } from "../utils/countries"

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.grayscale[100]};

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    padding-top: 52px;
  }
`
interface PageProps {
  data: {
    page: ContentfulPage
  }
  pageContext: {
    slug: string
    locale: string
    shopstoryContent: any
    shopTheLookModules: ContentfulShopTheLook[]
    gridModules: ContentfulGrid[]
    seo: {
      breadcrumbsList: BreadcrumbsListItem[]
    }
    isHomepage: boolean
    homePageAlternatives: any
  }
}

const PageTemplate = ({ data, pageContext }: PageProps) => {
  let { page } = data
  const { seo, isHomepage, shopTheLookModules } = pageContext
  let shopTheLookItems = shopTheLookModules
  let faqSchema = null
  const { cart } = useStore()

  // TODO: Revisit this logic when the FAQ page is migrated to new modules
  if (pageContext.slug === "frequently-asked-questions") {
    faqSchema = getFaqSchema(
      // @ts-ignore
      page.contentModules[0].text?.raw,
      // @ts-ignore
      page.contentModules[0]?.text.references
    )
  }

  // Combined enriched grid modules with other ones
  const modules = page.contentModules
    ?.filter((i) => i.internal?.type)
    .map((module) => {
      const grid = pageContext.gridModules?.find(
        (grid) =>
          grid.contentful_id === (module as ContentfulGrid).contentful_id
      )

      if (grid) {
        return grid
      }

      return module
    })

  const pageData = {
    ...page,
    contentModules: modules,
  }

  const [homepageData, setHomepageData] = React.useState({
    contentModules: pageData.contentModules,
    isNavbarOverlapping: pageData.isNavbarOverlapping,
    navbarInWhite: pageData.navbarInWhite,
  })

  React.useEffect(() => {
    if (isHomepage) {
      const region = countryLookupName(cart?.shipping_address?.country_code)
      const alternative = pageContext.homePageAlternatives.find(
        (i) => i.region === region
      )

      if (alternative && alternative.contentModules?.length > 0) {
        const modules = alternative.contentModules
          ?.filter((i) => i.internal?.type)
          .map((module) => {
            const grid = pageContext.gridModules?.find(
              (grid) =>
                grid.contentful_id === (module as ContentfulGrid).contentful_id
            )

            if (grid) {
              return grid
            }

            return module
          })
        setHomepageData({
          ...pageData,
          isNavbarOverlapping: alternative.isNavbarOverlapping,
          navbarInWhite: alternative.navbarInWhite,
          contentModules: modules,
        })
      } else {
        setHomepageData({
          ...pageData,
          contentModules: pageData.contentModules,
          isNavbarOverlapping: pageData.isNavbarOverlapping,
          navbarInWhite: pageData.navbarInWhite,
        })
      }
    }
  }, [isHomepage, cart?.shipping_address?.country_code])

  return (
    <Layout
      pullUp={page.isNavbarOverlapping}
      hidePromotional={page.hidePromotionalElement}
      isSizeGuide={page.pageTitle === "Size Guide" ? true : false}
      isHomepage={isHomepage}
      navbarInWhite={page.navbarInWhite}
    >
      <SEO
        metaImage={page.metaImage && page.metaImage.file.url}
        title={page.metaTitle || page.pageTitle}
        description={page.metaDescription?.metaDescription}
        canonicalUrl={isHomepage ? "" : `/${pageContext.slug}`}
        schema={faqSchema}
        breadcrumbsList={seo?.breadcrumbsList}
        isHomepage={isHomepage}
      />
      <Container>
        {page.contentModules?.length && shopTheLookItems?.length > 0 ? (
          <ShopTheLook
            items={shopTheLookItems}
            pageTitle={page.pageTitle}
            pageDescription={page.pageDescription}
            hasTopGrid={page.hasTopGrid}
          />
        ) : (
          <>
            {pageData.contentModules?.length > 0 && !isHomepage && (
              <Page data={pageData} />
            )}
            {pageData.contentModules?.length > 0 && isHomepage && (
              <Page data={homepageData} />
            )}
            {/* TODO: Delete ContentModules component when content is migrated to new modules */}
            <ContentModules
              pullUp={page.isNavbarOverlapping}
              allModules={page.contentModules}
              shopstoryContent={pageContext.shopstoryContent}
            />
          </>
        )}
      </Container>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageQuery($slug: String!, $locale: String) {
    page: contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...PageContent
    }
  }
`
